import React, {useState, useEffect} from 'react';
import logo from '../logo.svg';
import './App.css';
import {Container, createMuiTheme, MuiThemeProvider, Paper, Typography} from "@material-ui/core";
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

import CssBaseline from '@material-ui/core/CssBaseline';
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'

import ReactPWAInstallProvider from "react-pwa-install";
import config from '../config'
import {AppStateProvider} from "../hooks/useAppState";
import theme from '../config/theme'
import ApplicationRouter, {WebRouter} from "../router";
import {DatabaseProvider} from "../hooks/useDatabase";
import {ToastContainer} from "react-toastify"
import {WebAppProvider} from "../hooks/useWebAppState";
import LoadingIndicator from "../components/LoadingIndicator";
import Dexie from 'dexie';
import DatabaseService from '../database/';
const queryClient = new QueryClient();

async function clearIndexedDB() {
    try {
        const dbExists = await Dexie.exists(DatabaseService.name);

        if (dbExists) {
            await Dexie.delete(DatabaseService.name);
            console.log(`Deleted Dexie database: ${DatabaseService.name}`);
        }

       
        console.log(`Reinitialized Dexie database: ${DatabaseService.name}`);
    } catch (error) {
        console.error('Error clearing IndexedDB (Dexie):', error);
    }
}


async function clearAllStorage() {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(";").forEach((cookie) => {
        const [name] = cookie.split("=");
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
    await clearIndexedDB();
    console.log('All application storage has been cleared.');
}


function TabletAppWithProvider() {
    const [isDataChecked, setIsDataChecked] = useState()

    useEffect(() => {

        (async ()=> {
             // Check for the existence of `data-sync` and its value
        const metadataVersion = localStorage.getItem('metadata-version');

        if (metadataVersion !== '2025') {
            console.log('metadata-version does not exist or is incorrect. Clearing storage...');
            await clearAllStorage();

            // Set the correct key-value pair
            localStorage.setItem('metadata-version', '2025');
            console.log('Set metadata-version to 2025');
        } else {
            console.log('data is properly up to date');
        }
        setIsDataChecked(true)
        })()
    }, []);  
    
    
  if(!isDataChecked) {
    return (<div className="full-height full-width flex-column justify-center align-center">
        <LoadingIndicator text={'Setting up Survey Optimization. Could take several minutes or more.'}/>
        <Typography>Ensuring Data is up to date</Typography>
    </div>)
  }  
  return (
      <MuiThemeProvider theme={theme}>
          {serviceWorkerRegistration.register()}
          <>
              <CssBaseline />
              <ReactPWAInstallProvider>
                  <QueryClientProvider client={queryClient}>
                      <DatabaseProvider>
                          <ToastContainer />
                          <AppStateProvider config={config}>
                              <Container component={Paper} disableGutters className="app-container flex-container flex-column align-center"  maxWidth={'lg'}>
                                  <ApplicationRouter />
                              </Container>
                          </AppStateProvider>
                      </DatabaseProvider>
                  </QueryClientProvider>
              </ReactPWAInstallProvider>
          </>
      </MuiThemeProvider>
  )
}


function WebAppWithProvider() {
    return (
        <>
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <ToastContainer/>
                <WebAppProvider config={config}>
                    <Container component={Paper} disableGutters className="app-container flex-container flex-column align-center"  maxWidth={'lg'}>
                        <WebRouter />
                    </Container>
                </WebAppProvider>
            </MuiThemeProvider>
        </>
    )
}

console.log(process.env.REACT_APP_IS_WEB === 'true')

const App = process.env.REACT_APP_IS_WEB === 'true' ? WebAppWithProvider : TabletAppWithProvider
export default App