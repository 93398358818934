import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from './config';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    DATA_COLLECTOR_LOGIN_MESSAGE:'THE MATERIAL EMBODIED IN THIS SOFTWARE IS PROVIDED TO YOU "AS-IS" AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE. IN NO EVENT SHALL THE CENTERS FOR DISEASE CONTROL AND PREVENTION (CDC) OR THE UNITED STATES (U.S.) GOVERNMENT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION, LOSS OF PROFIT, LOSS OF USE, SAVINGS OR REVENUE, OR THE CLAIMS OF THIRD PARTIES, WHETHER OR NOT CDC OR THE U.S. GOVERNMENT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS, HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE POSSESSION, USE OR PERFORMANCE OF THIS SOFTWARE.',
                    Survey_instructions_headerInstructions: 'Please read these instructions before starting.',
                    Survey_Instructions_bodyInstructions: `This survey is about health behavior. It has been developed so you can tell us what you do that may affect your health. The information you give will be used to improve health education for young people like yourself.`,
                    Survey_Instructions_bodyInstructions2: "The answers you give will be kept private. No one will know what you say. Answer the questions based on what you really do.",
                    Survey_Instructions_bodyInstructions3: "Completing the survey is voluntary. Whether or not you answer the questions will not affect your grade in this class. If you are not comfortable answering a question, just leave it blank.",
                    Survey_Instructions_bodyInstructions4: "The questions that ask about your background will be used only to describe the types of students completing this survey. The information will not be used to find out your name. No names will ever be reported.",
                    Survey_Instructions_bodyInstructions5: "Make sure to read every question. When you are finished, follow the instructions of the person giving you the survey.",
                    NewSurvey: "The survey has not been started.",
                    OldSurvey: "The survey is in progress.",
                    CompletedSurvey: "The Survey has been completed.",
                    endMessage: `Public reporting burden for this collection of information is estimated to average 25 minutes per response, including time for reviewing instructions, searching existing data sources, gathering and maintaining the data needed, and completing and reviewing the collection of information. An agency may not conduct or sponsor, and a person is not required to respond to a collection of information unless it displays a currently valid OMB control number. Send comments regarding this burden estimate or any other aspect of this collection of information, including suggestions for reducing this burden to: CDC Reports Clearance Officer, 1600 Clifton Road, MS D-74, Atlanta, GA 30333, ATTN:PRA (0920-0493)`,
                    Progress:"Progress",
                    FormMessage1: `Form Approved`,
                    FormMessage2: "OMB No.: 0920-0493 ",
                    FormMessage3: "Exp. Date: 11/30/2027",
                    SurveyError: " Your survey closed unexpectedly. Please login using the same access code to return to where you left off.",
                    ThankYouMessage: 'Thank you very much for your help.',
                    SecondInstructions: `Use your finger (or mouse, if using a computer) to select the answer that best fits what you know, believe, or do.`,
                    SecondInstructions2: "If you need to change your answer, select a different response. If you want to delete your answer, select the same response again to de-select it.",
                    SecondInstructions3: "At the bottom of each screen is a set of arrows. Use these arrows to move forward and backward through the survey. You may need to scroll down on some pages to see the arrows.",
                    SecondInstructions4: "Your answers will automatically be saved as you advance through the survey.",
                    SecondInstructions6: "If you need to log out of the survey before you have fully completed it, use the same Access Code to log back in. You will be returned to your stopping point.",

                    TitleInstructions: "2025 National Youth Risk Behavior Survey",
                    ReviewScreenSubmitMsg: "Once you have submitted your survey, you will no longer be able to log in and make changes.  Do you want to submit?",
                    ReviewScreenInstructionText: `The list below shows the question(s) for which you have not provided a response.
        Select the question number to go back and answer that question.`,
                    ReviewScreenWarningText: "Warning! This survey has unanswered questions.",
                    SurveySubmittedText: "Your survey has been completed and submitted. Thanks for participating in the Youth Risk Behavior Survey (YRBS).",
                    LanguageSelectionPageTitle: "Language Selection Page",
                    LangaugeSelectionPage1:"Please select  the language you feel most comfortable using to complete the Youth Risk Behavior Survey (YRBS).",
                    EnglishButton:"English",
                    SpanishButton:"Spanish",
                    StudentLogin:"Student Login",
                    StudentLoginTitle:"Enter the access code on the card given to you by the Survey Administrator",
                    StudentLoginButton: 'Start Survey',
                    StartButton: 'Start',
                    ReviewThanks: 'Thank you for your participation!',
                    ReviewButton: 'REVIEW SURVEY FROM START',
                    StudentLoginBottomText: 'THE MATERIAL EMBODIED IN THIS SOFTWARE IS PROVIDED TO YOU "AS-IS" AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE. IN NO EVENT SHALL THE CENTERS FOR DISEASE CONTROL AND PREVENTION (CDC) OR THE UNITED STATES (U.S.) GOVERNMENT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION, LOSS OF PROFIT, LOSS OF USE, SAVINGS OR REVENUE, OR THE CLAIMS OF THIRD PARTIES, WHETHER OR NOT CDC OR THE U.S. GOVERNMENT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS, HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE POSSESSION, USE OR PERFORMANCE OF THIS SOFTWARE. ',
                    ContinueButton:'Continue',
                    SubmitButton: 'Submit',
                    LoginButton: 'Login',
                    AccessCode: 'Access Code (not case sensitive)',
                    Question: 'Question',
                    HeightInstructions: 'Use the dropdowns below to enter your height in feet and inches.',
                    HeightInfo: 'Your height is:',
                    Feet: 'Feet',
                    Inches: 'Inches',
                    WeightInstructions: 'Use the field below to enter your weight in pounds.',
                    WeightInfo: 'Your weight is:',
                    Pounds: 'Pounds',
                    HeightErrorMessage:"Invalid height. Please select a value for feet.",
                    WeightErrorMessage:"Is your answer correct? Please enter a whole number between 25 and 399 pounds.",
                    EndReviewButton: "Back to Review Summary",
                    ReusedAccessCode:"This access code was already used on a tablet and cannot be reused online.",
                    AccessCodeError: 'Access Code is required.',
                    LoadingText:'Please wait.',
                    SurveyCompleteErrorMessage: `You have completed and submitted the ${config.appTitle}. Thank you!`

                }
},
es: {
    translation: {
            DATA_COLLECTOR_LOGIN_MESSAGE:'LOS MATERIALES COMPRENDIDOS EN ESTE SOFTWARE SE LE SUMINISTRAN COMO TAL ("AS-IS") Y SIN GARANTÍA DE NINGUNA CLASE, EXPLÍCITA, IMPLÍCITA NI DE OTRO TIPO, INCLUSO SIN LÍMITE, NI GARANTÍA ALGUNA DE IDONEIDAD PARA UN FIN PARTICULAR. EN NINGÚN CASO SE RESPONSABILIZARÁ A LOS CENTROS PARA EL CONTROL Y LA PREVENCIÓN DE ENFERMEDADES (CDC) NI AL GOBIERNO DE LOS ESTADOS UNIDOS (EE. UU.) FRENTE A USTED O CUALQUIER OTRA PERSONA POR DAÑOS DE CUALQUIER ÍNDOLE, BIEN SEAN DIRECTOS, ESPECIALES, FORTUITOS, INDIRECTOS O CONSECUENCIALES, NI POR NINGÚN DAÑO EN ABSOLUTO, INCLUIDOS SIN LÍMITACIONES PÉRDIDA DE GANANCIAS, PÉRDIDA DE USO, DE AHORROS O INGRESOS, O DE RECLAMACIONES DE TERCERAS PARTES, YA SEA QUE LOS CDC O EL GOBIERNO DE LOS EE. UU. HUBIERAN SIDO AVISADOS DE LA POSIBILIDAD DE TAL PÉRDIDA, DE CUALQUIER MODO CAUSADA Y EN CUALQUIER TEORÍA DE RESPONSABILIDAD, SURGIDA DE O EN CONEXIÓN CON LA POSESIÓN, EL USO O EL FUNCIONAMIENTO DE ESTE SOFTWARE.',
            LanguageSelectionPageTitle: 'Pagina para Seleccionar tu Idioma',
            LanguageSelectionPage1: 'Por favor selecciona el idioma en el que te sientas más cómodo(a) para llenar la Encuesta Nacional sobre Comportamientos de Riesgo (YRBS por sus siglas en inglés).',
            EnglishButton: 'Inglés',
            SpanishButton: 'Español',
            StudentLoginTitle:`Introduce el código de acceso que aparece en la tarjeta que el Administrador/la Administradora te dió`,
            StudentLoginButton:'Empezar Encuesta',
            TitleInstructions:'Encuesta nacional sobre comportamientos de riesgo entre los jóvenes 2025',
            NewSurvey: 'La encuesta no ha comenzado.',
            OldSurvey: 'La encuesta está en progreso.',
            Survey_instructions_headerInstructions: 'Por favor lee estas instrucciones antes de comenzar.',
            Survey_Instructions_bodyInstructions: 'Esta encuesta trata de comportamientos de la salud. Se ha creado para que nos cuentes cosas que haces que puedan afectar tu salud. La información que nos proporciones se usará para mejorar la educación de la salud para jóvenes como tú.',
            Survey_Instructions_bodyInstructions2: 'Todas las respuestas que nos proporciones serán confidenciales. Nadie sabrá lo que contestas. Responde a las preguntas según lo que realmente haces.',
            Survey_Instructions_bodyInstructions3: 'La participación en la encuesta es voluntaria. El responder o no a las preguntas no afectará tu nota en esta clase. Si te incomoda responder a alguna pregunta, déjala en blanco.',
            Survey_Instructions_bodyInstructions4: 'Las preguntas sobre tus antecedentes se usarán solo para describir los tipos de estudiantes que completan esta encuesta. La información no se usará para descubrir tu nombre. Ningún nombre será reportado.',
            Survey_Instructions_bodyInstructions5: 'Asegúrate de leer cada pregunta. Cuando termines, sigue las instrucciones de la persona que está administrando la encuesta.',
            endMessage: 'Se estima que la carga del público para reportar datos en esta recopilación de información tendrá un promedio de 25 minutos por respuesta, e incluye el tiempo para revisar instrucciones, buscar en fuentes de datos existentes, recopilar y mantener los datos necesarios, y completar y revisar la recopilación de información. Una agencia no puede llevar a cabo ni patrocinar la recopilación de información y las personas no están obligadas a responder, a menos que muestre un número de control de la Oficina de Administración y Presupuesto (OMB) válido y vigente. Envía tus comentarios acerca de esta carga estimada o cualquier otro aspecto de esta recopilación de información, incluyendo tus sugerencias para reducir la carga a: CDC Reports Clearance Officer, 1600 Clifton Road, MS D-74, Atlanta, GA 30333, ATTN:PRA (0920-0493)',
            ThankYouMessage: 'Muchas gracias por tu ayuda.',
            SecondInstructions: 'Usa tu dedo (o el ratón de una computadora) para seleccionar la respuesta que sea más apropiada a lo que tú sabes, crees o haces.',
            SecondInstructions2: 'Si necesitas cambiar tu respuesta, selecciona una respuesta diferente. Si quieres borrar tu respuesta, selecciona la misma respuesta otra vez para deseleccionarla.',
            SecondInstructions3: 'En la parte inferior de la pantalla hay un par de flechas. Usa estás flechas para avanzar y regresar en la encuesta. Tal vez tengas que desplazar hacia abajo en algunas páginas para ver las flechas.',
            SecondInstructions4: 'Tus respuestas serán automáticamente guardadas como avances en la encuesta.',
            SecondInstructions6: 'Si necesitas cerrar la sesión de la encuesta antes de que hayas terminado completamente, usa tu código de acceso para regresar a la sesión de nuevo. Regresarás al punto donde paraste.',
            StartButton: 'EMPEZAR',
            ReviewScreenWarningText: '¡Aviso! La encuesta tiene preguntas sin responder.',
            ReviewScreenInstructionText:  'La lista de abajo enseña la(s) pregunta(s) a las cuales no has dado una respuesta. Selecciona cada numero de la pregunta para regresar y contestar la pregunta. ',
            ReviewScreenSubmitMsg: 'Una vez que hayas enviado tu encuesta, no podrás ingresar y hacer cambios. ¿Quieres enviarla? ',
            ReviewThanks:'¡Gracias por tu participación!',
            SurveySubmittedText: 'Tu encuesta ha sido llenada y enviada. Gracias por participar en la Encuesta Nacional sobre Comportamientos de Riesgo (YRBS por sus siglas en inglés)',
            LoginButton: 'ACCESO',
            FormMessage1:'Formulario aprobado',
            FormMessage2:'N.º OMB: 0920-0493',
            FormMessage3: 'Fecha de vencimiento: 30/11/2027',
            ContinueButton: 'CONTINUAR',
            ReviewButton: 'REVISA LA ENCUESTA DESDE EL INICIO',
            SubmitButton: 'ENVIAR',
            AccessCode: 'Código de Acceso (no es sensible a mayúsculas y minúsculas)',
            Question: 'Pregunta',
            HeightInstructions: 'Selecciona tu estatura en pies y pulgadas de las siguientes listas.',
            HeightInfo: 'Tu estatura es:',
            Feet: 'Pies',
            Inches: 'Pulgadas',
            WeightInstructions: 'Indica tu peso en libras en el cuadro corespondiente.\n',
            WeightInfo: 'Tu peso es:',
            Pounds: 'Libras',
            Progress:"Progreso",
            StudentLogin:"Acceso estudiantil",
            HeightErrorMessage:"La estatura no es válida. Por favor selecciona un valor en pies.",
            WeightErrorMessage:"¿Tu respuesta es correcta? Por favor ingresa un número entero entre 25 y 399 libras.",
            EndReviewButton:"Regresar al resumen de la revisión",
            SurveyError:"Tu encuesta se cerró inesperadamente. Por favor inicia la sesión usando el mismo código de acceso para regresar donde te quedaste. ",
            ReusedAccessCode:"Este código de acceso ya se usó en una tableta y no se puede reutilizar en línea.",
            AccessCodeError: 'Se requiere código de acceso.',
            LoadingText: 'Espere, por favor.',
            SurveyCompleteErrorMessage: "Has completado y enviado la encuesta nacional sobre comportamientos de riesgo entre los jóvenes. ¡Gracias!"
    }
}
}
});

export default i18n;