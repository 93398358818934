import React, {useState} from "react";
import "./index.css";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {SURVEY_ROUTE, LOCK_ROUTE} from "../../config/routes";

import {useHistory} from "react-router-dom";
const useStyles = makeStyles({
    root: {
        marginTop: '20px',
        padding: 20

    }
})
const LockButtonScreen = () => {
    const history = useHistory()
    const classes = useStyles()

    const onStudentloginPressed = ()=>{
        history.replace(SURVEY_ROUTE)
    }

    const onAdministratorAccessPressed = () => {
        history.replace(LOCK_ROUTE)

    }
    return (
        <div className="screen-container align-center">
            <Typography gutterBottom className=" margin-above-double align-self-center" color="primary" variant="h4" >Application is Locked</Typography>
            <div className="form-container-base full-width">
                <Button onClick={onStudentloginPressed} classes={{root: classes.root}} className="margin-above-double margin-below-double" color="primary" variant="contained" fullWidth type="submit">
                    <Typography variant="h5"><b>Students - Click here for  login</b></Typography>
                </Button>
            </div>
            <div className="form-container-base full-width">
                <Button onClick={onAdministratorAccessPressed} classes={{root: classes.root}} className="margin-above-double margin-below-double" color="primary" variant="outlined" fullWidth type="submit">
                    <Typography variant="h5"><b>Administrator Access</b></Typography>
                </Button>
            </div>
        </div>
    )

}

export default LockButtonScreen