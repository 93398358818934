import React, {useState,useEffect,useCallback} from "react";
import "./index.css";
import {RadioGroup,Radio,TextField, Select,MenuItem,InputLabel} from "@material-ui/core";
import * as yup from "yup";
import {useFormik} from "formik";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import images from "../../utils/images";
import config from "../../config"
import databaseService from "../../services/datbaseService";
import DatabaseService from "../../database";
import apiService from "../../services/apiService";
import {useTranslation} from "react-i18next";
import LoadingIndicator from "../LoadingIndicator";
import helpers from "../../utils/helpers";
const validationSchema = yup.object({
	value: yup
		.number("Enter your pin")
		.min(0)
		.max(30),
});
const validationSchema2 = yup.object({
	value: yup
		.number("Enter your pin")
		.min(1)
		.max(30),
});



const WeightSelect = ({activeSurveyResponse,isWeb,survey, inputResponse,backupID,responses}) => {
	const {t} = useTranslation()
	const validationSchema3 = yup.object({
		weight: yup
			.number()
			.integer()
			.min(25)
			.max(399)
	});

	const formik = useFormik({
		initialValues: {
			weight: !!activeSurveyResponse && activeSurveyResponse.length>0 ? activeSurveyResponse[0].ResponseValue :0,
		},
		validationSchema: validationSchema3,
		validateOnChange: true,
		onSubmit: (value) => console.log('record answer')
	})


	const handleWeightChange = async (event) =>{

		if(!!event.target.value && event.target.value>=25 && event.target.value < 400){
			if(isWeb) {
				await apiService.insertSingleAnswerSurveyResponse(survey,inputResponse.QuestionID,inputResponse.ResponseID,event.target.value.toString(),true, backupID);

			} else {
				await databaseService.insertSingleAnswerSurveyResponse(survey,inputResponse.QuestionID,inputResponse.ResponseID,event.target.value.toString(),true);
			}
		} else{
			if(isWeb) {
				await apiService.insertSingleAnswerSurveyResponse(survey,inputResponse.QuestionID,responses[0].ResponseID,undefined,false, backupID);
			} else {
				await databaseService.insertSingleAnswerSurveyResponse(survey,inputResponse.QuestionID,responses[0].ResponseID,undefined,false);
			}
		}
	}

	return (
		<div className="full-width margin-below-double">
			<div className="full-width flex-row justify-center">
				<Typography className="align-self-center">
					<b>{t('WeightInstructions')}</b>
				</Typography>
			</div>
			<div className="full-width flex-row justify-center margin-below-double">
				<Typography>{t('WeightInfo')} &nbsp;</Typography>
				<Typography>{formik.values.weight}</Typography>
				<Typography>&nbsp; {t('Pounds')}</Typography>
			</div>
			<div className="flex-row margin-above-double full-width">
					<TextField
						fullWidth
						placeholder={t('Pounds')}
						variant="outlined"
						id="value"
						name="weight"
						type="number"
						label={t('Pounds')}
						value={formik.values.weight}
						error={Boolean(formik.errors.weight)}
						helperText={Boolean(formik.errors.weight) && t('WeightErrorMessage')}
						onChange={formik.handleChange}
						onBlur={() => handleWeightChange({target:{value: formik.values.weight}})}
					/>
			</div>
		</div>
	)
}
function ResponseDescriptor({responses,question, numberOfResponses,activeSurveyResponse,survey, isWeb,backupID,language, skipRules}) {
	let validSkipRule;

	if (skipRules && activeSurveyResponse && activeSurveyResponse.length) {
		for (let i = 0; i < skipRules.length; i++) {
			const r = skipRules[i];
			
			if (
			r.values[0].QuestionID === activeSurveyResponse[0].QuestionID &&
			r.values[0].ResponseID === activeSurveyResponse[0].ResponseID
			) {
				validSkipRule = r;
				break; // stop once the first match is found
			}
		}
	}

	const [inches, setInches] = useState(activeSurveyResponse!== undefined && activeSurveyResponse.length>0? Math.floor(activeSurveyResponse[0].ResponseValue % 12) : undefined);
	const [feet, setFeet] = useState(activeSurveyResponse!== undefined && activeSurveyResponse.length>0? Math.floor(activeSurveyResponse[0].ResponseValue / 12) : undefined)
	const [hasError, setHasError] = useState(false);
	const [multiValue, multisetValue] = useState([]);
	const [singleValue, singlesetValue] = useState("");
	const [hideResponse, setHideResponse] = useState(undefined)
	const [isReady, setIsReady] = useState(true)
	useEffect(() => {
		const initializeMultiValues = () => {
			let values = [];
			if(activeSurveyResponse && !!activeSurveyResponse.length) {
				responses.forEach((res) => {
					res.descriptors.forEach(des => {
						activeSurveyResponse.forEach((act) =>{
							if(act.ResponseID===des.ResponseID)
								console.log(act.Text);
							values.push(act.ResponseID);
						})
					})
				})
				multisetValue(values);
			}

		}
		const initializeSingleValue = () => {
			responses.forEach((res) => {
				res.descriptors.forEach(des => {
					if(!!activeSurveyResponse && !!activeSurveyResponse.length  && activeSurveyResponse[0].ResponseID===des.ResponseID) {
						singlesetValue(des.ResponseID);
					}
				})
			})
		}

		const checkResponseHiding = async () => {
			const responseHide = config.responseHiding[question.QuestionID.toString()]
			if(!!responseHide) {
				let activeResponse
				if(isWeb) {
					activeResponse = await apiService.getActiveSurveyResponse(survey, responseHide.QuestionID)
				} else {
					activeResponse = await databaseService.getActiveSurveyResponse(survey, responseHide.QuestionID)
				}

				if(responseHide.ResponseID.indexOf(activeResponse[0].ResponseID) !== -1) {
					setHideResponse(responseHide.HideResponseID)
				}
			}
			setIsReady(true)
		}

		(async () => {
			try {
				initializeMultiValues()
				initializeSingleValue()
				await checkResponseHiding()
			} catch (err) {}
		})()

	}, [])


	const deactivateSkippedQuestions = async (localSkipRule) => {
		const rule = localSkipRule || validSkipRule
		if(rule) {
			const firstPageID = question.PageID + 1
			let lastQuestion;
			if(isWeb) {
				lastQuestion = await apiService.getQuestionById(rule.rule.ForwardSkipQuestion)
				for(let i = firstPageID; i < lastQuestion.PageID; i++) {
					const que = await apiService.getQuestionsByPageID(i);
					await apiService.insertCodebookValueSurveyResponse(survey, que[0].QuestionID, 'ND', backupID)
				}
			} else {
				lastQuestion = await databaseService.getQuestionByQuestionId(rule.rule.ForwardSkipQuestion)
				for(let i = firstPageID; i < lastQuestion.PageID; i++) {
					const que = await databaseService.getQuestionsByPageID(i);
					await databaseService.insertSurveyResponseByCodebookValue(survey, que[0].QuestionID, 'ND')
				}
			}
		}
	}

	let responseDescriptors = [];

	
	responses.forEach((res) => {
		res.descriptors.forEach(des => {

			if((language === "English" && des.LanguageCodeID ===1) || (language === "Spanish" && des.LanguageCodeID ===2) ) {
				responseDescriptors.push(des)
			}
		})
	})
	const inputResponse = responses.find((res) => res.descriptors && res.descriptors.length);


	const useStyles = makeStyles({
		root: {
			margin: '20px 50px 0%'

		},
		question:{
			marginTop: '10px',
			marginBottom: '10px',
			backgroundColor: "#f6f6f6",
			borderColor: "#ddd",
			borderRadius: '.3125em ' ,   
			fontWeight: 'bold',
			borderWidth: '1px',
			borderStyle: 'solid',
			width: '100%'
		},
		})

		
	const classes = useStyles();

	const ResponseImage = ({imageName}) => (
		<img alt={helpers.getImageAltText(imageName)} src={images[imageName]} />
	)

	const MultiSelect = () => {


		const handleChange = async (event,ev) => {
			ev.stopPropagation();
			ev.preventDefault();

			const newValue = event.target.responseID;
			const isValue = multiValue.indexOf(newValue) !== -1
			let updatedValue = multiValue.slice();
			if(isValue) {
				updatedValue = updatedValue.filter(val => val !== newValue)
				if(isWeb) {
					apiService.upsertMultipleAnswerSurveyResponse(survey, question.QuestionID, event.target.responseID, undefined,false, backupID)
				} else {
					databaseService.upsertMultipleAnswerSurveyResponse(survey, question.QuestionID, event.target.responseID, undefined,false);
				}
			} else {
				if(isWeb) {
					apiService.upsertMultipleAnswerSurveyResponse(survey, question.QuestionID, event.target.responseID, undefined,true, backupID)
				} else {
					databaseService.upsertMultipleAnswerSurveyResponse(survey, question.QuestionID, event.target.responseID, undefined,true);
				}
				updatedValue.push(newValue);
			}
			console.log("in handleChange, the values are after", updatedValue)

			multisetValue(updatedValue);
		}

		return (
			<FormControl component="fieldset">
				<FormGroup>
					{
						responseDescriptors.map(option => {
							if(option.DescriptorCodeID === 6) {
								return (
									<ResponseImage imageName={option.Text}/>
								)
							} else {
								return (
									<div className="full-width" onClick={(ev) => handleChange({target: {checked: option.Text,responseID:option.ResponseID}},ev)} >
										<FormControlLabel
											control={<Checkbox color="primary" checked={multiValue.indexOf(option.ResponseID) !== -1} name={option.Text}/>}
											label={
											<Typography>
												<div dangerouslySetInnerHTML={{ __html: option.Text }} />
											</Typography>}
										/>
									</div>
								)
							}
						})
					}
				</FormGroup>
			</FormControl>
		)
	}

	const HeightSelect = () => {
		const {t} = useTranslation()
		const handleChangeInches = async (event) => {
			const value = event.target.value ? parseInt(event.target.value) : undefined;
			if(inches !== event.target.value){
				setInches(value)
				let val = (12 * (feet ? feet : 0) ) + parseInt(event.target.value);
				if(val < 36){
					setHasError(true);
				} else {
					if(isWeb) {
						await apiService.insertSingleAnswerSurveyResponse(survey,inputResponse.QuestionID,inputResponse.ResponseID,val.toString(),true, backupID);
					} else {
						if(event.key == 'Enter'){
							event.target.blur();
						}
						await databaseService.insertSingleAnswerSurveyResponse(survey,inputResponse.QuestionID,inputResponse.ResponseID,val.toString(),true);

					}
				}
			} 
		}

		const handleChangeFeet = async (event) => {
			const value = event.target.value ? parseInt(event.target.value) : undefined;
			
			if(feet !== event.target.value){
				setHasError(false);
				setFeet(value)
				let val = 12 * value + (inches === undefined ? 0 : inches)
				if(isWeb) {
					await apiService.insertSingleAnswerSurveyResponse(survey,inputResponse.QuestionID,inputResponse.ResponseID,val.toString(),true, backupID);

				} else {
					await databaseService.insertSingleAnswerSurveyResponse(survey,inputResponse.QuestionID,inputResponse.ResponseID,val.toString(),true);

				}
			}
		}
		
		return (
			<div className="full-width margin-below-double">
				<div className="flex-row full-width justify-center">
					<Typography>
						<b>{t('HeightInstructions')}</b>
					</Typography>
				</div>
				<div className="full-width flex-row justify-center margin-below">
					<Typography>{t('HeightInfo')} &nbsp;</Typography>
					<Typography>{`${feet ? feet : '0'} ${t('Feet')}`}&nbsp;</Typography>
					<Typography>{`${inches ? inches : '0'} ${t('Inches')}`}</Typography>
				</div>
				<div className="flex-row full-width">
					<div className={`flex-grow margin-right`} >
						<TextField
							variant="outlined"
							fullWidth
							id="feet"
							select
							value={feet}
							label={ <Typography style={hasError ? {color:"red" } : {color:"rgba(0, 0, 0, 0.54)"}
						}>{t('Feet')}</Typography>}
							placeholder={t('Feet')}
							onChange={handleChangeFeet}
						>
							{config.feetDisplayArr.map((option) => (
									<MenuItem  key={option} value={option}> {option}</MenuItem>
								)
							)}
						</TextField>
						{hasError && <Typography style={{color:"red"}}>{t('HeightErrorMessage') }</Typography>}
					</div>
					<div className="flex-grow">
						<TextField
							variant="outlined"
							fullWidth
							id="inches"
							select
							value={inches}
							label={t('Inches')}
							placeholder={t('Inches')}
							onChange={handleChangeInches}
						>
							{config.inchesDisplayArr.map((option) => (
									<MenuItem  key={option} value={option}> {option}</MenuItem>
								)
							)}
						</TextField>
					</div>
				</div>
			</div>
		)



	}



	const SingleSelect = () => {
		const handleChange = async (event, ev) => {
			ev.stopPropagation();
			ev.preventDefault();
			if(event.target.value !== hideResponse) {
				const locaValidSkipRule = !!skipRules ? skipRules.find(r => r.values[0].QuestionID === question.QuestionID &&  r.values[0].ResponseID === event.target.value) : undefined
				if(locaValidSkipRule || validSkipRule) {
					deactivateSkippedQuestions(locaValidSkipRule)
				}

				if(singleValue !== event.target.value){
					singlesetValue(event.target.value)
					if(isWeb) {
						await apiService.insertSingleAnswerSurveyResponse(survey,question.QuestionID,event.target.value,undefined,true,backupID);

					} else {
						await databaseService.insertSingleAnswerSurveyResponse(survey,question.QuestionID,event.target.value,undefined,true);

					}
				} else {
					if(isWeb) {
						await apiService.insertSingleAnswerSurveyResponse(survey,question.QuestionID,event.target.value,undefined,false, backupID);

					} else {
						await databaseService.insertSingleAnswerSurveyResponse(survey,question.QuestionID,event.target.value,undefined,false);

					}
					singlesetValue(-1)
				}
			}



				
			
			
		}

		return (
			<RadioGroup value={singleValue} className={classes.root}>
				{responseDescriptors.map((option) => {
					console.log("value",singleValue)
						if(option.DescriptorCodeID === 6) {
							return (
								<ResponseImage imageName={option.Text}/>
							)
						
						} else if(option.ResponseID !== hideResponse) {
							return (
								<div key={option.ResponseID} className="full-width" onClick={(ev) => handleChange({target:{ value: option.ResponseID}}, ev) }>
									<FormControlLabel
										className={classes.question}
										label={
											<Typography>
												<div dangerouslySetInnerHTML={{ __html: option.Text }} />
											</Typography>
										}
										control={<Radio color="primary" />}
										value={option.ResponseID}
									/>
								</div>
							)
						}
					}
				)}
			</RadioGroup>
		);
	}

	const TextBox0to30 = () => {
		const formik = useFormik({
			initialValues: {
				value: ""
			},
			validationSchema,
			onSubmit: () => console.log('record answer')
		})

		const imageDes = responseDescriptors.filter((res) => res.DescriptorCodeID === 6)

		return (
			<form className="full-width" onSubmit={formik.handleSubmit}>
				{!!imageDes && imageDes.length && imageDes.map((img) => (
					<ResponseImage imageName={img.Text}/>
				))}
				<TextField
					fullWidth
					id="value"
					name="value"
					type="number"
					onChange={formik.handleChange}
					value={formik.values.value}
					error={formik.touched.value && Boolean(formik.errors.value)}
					helperText={formik.touched.value && formik.errors.value}
				/>
				<Button fullWidth type="submit" variant="contained" color="primary">
					Submit
				</Button>
			</form>
		)
	}

	const TextBox1to30= () => {
		const formik = useFormik({
			initialValues: {
				value: ""
			},
			validationSchema2,
			onSubmit: () => console.log('record answer')
		})
		const imageDes = responseDescriptors.filter((res) => res.DescriptorCodeID === 6)

		return (
			<form className="full-width" onSubmit={formik.handleSubmit}>
				{!!imageDes && imageDes.length && imageDes.map((img) => (
					<ResponseImage imageName={img.Text}/>
				))}
				<TextField
					fullWidth
					id="value"
					name="value"
					type="number"
					onChange={formik.handleChange}
					value={formik.values.value}
					error={formik.touched.value && Boolean(formik.errors.value)}
					helperText={formik.touched.value && formik.errors.value}
				/>
				<Button fullWidth type="submit" variant="contained" color="primary">
					Submit
				</Button>
			</form>
		)
	}

	const TextBoxDescriptor= () => {
		const formik = useFormik({
			initialValues: {
				value: ""
			},
			onSubmit: () => console.log('record answer')
		})
		const imageDes = responseDescriptors.filter((res) => res.DescriptorCodeID === 6)

		return (
			<form className="full-width" onSubmit={formik.handleSubmit}>
				{!!imageDes && imageDes.length && imageDes.map((img) => (
					<ResponseImage imageName={img.Text}/>
				))}
				<TextField
					fullWidth
					id="value"
					name="value"
					type="number"
					onChange={formik.handleChange}
					value={formik.values.value}
					error={formik.touched.value && Boolean(formik.errors.value)}
					helperText={formik.touched.value && formik.errors.value}
				/>
				<Button fullWidth type="submit" variant="contained" color="primary">
					Submit
				</Button>
			</form>
		)
	}
	if(isReady) {
		switch (inputResponse.InputTypeCodeID) {
			case 1:
				if (numberOfResponses > 1) {
					return <MultiSelect />
				} else {
					return <SingleSelect/>
				}
			case 2:
				return <TextBoxDescriptor />
			case 3:
				return <WeightSelect activeSurveyResponse={activeSurveyResponse} isWeb={isWeb} survey={survey} inputResponse={inputResponse} backupID={backupID} responses={responses}/>
			case 4:
				return <HeightSelect />
			case 8:
				return <TextBox0to30 />
			case 9:
				return <TextBox1to30 />
			default:
				return <TextBoxDescriptor />
		}
	}

	return <LoadingIndicator />

}

export default React.memo(ResponseDescriptor)
