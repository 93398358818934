import React, {createContext, useCallback, useEffect, useMemo, useState} from "react";
import "./index.css";
import StudentLoginScreen from "../../StudentLoginScreen";
import useStudentPermissions from "../../../hooks/useStudentPermissions";
import SurveyInstructionsScreen from "../../SurveyInstructionsScreen";
import databaseService from "../../../services/datbaseService";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Message from "../../../components/Message";
import SurveyReviewScreen from "../../SurveyReviewScreen";
import QuestionsScreen from "../../QuestionsScreen";
import SectionScreen from "../../SectionsScreen";
import useAppState from "../../../hooks/useAppState";
import isEmpty from "lodash/isEmpty";
import { ContactlessOutlined } from "@material-ui/icons";
import useWebAppState from "../../../hooks/useWebAppState";
import config from "../../../config";
import apiService from "../../../services/apiService";
import Question from "../../../database/Tables/Question";
import {useTranslation} from 'react-i18next';
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";


export default function SurveyScreen() {
	const {t,i18n} =useTranslation();

	const {
		userSchoolClass,
		studentLoginID,
		applicationSession,
		survey,
		surveyStatus,
		logout,
		backupID,
		language,
		setLanguage,
	} = useWebAppState()
	console.log(userSchoolClass,  'User School Class')

	const [surveyInstructionsViewed, setSurveyInstructionsViewed] = useState(false);
	const [surveyStarted, setSurveyStart] = useState(false);
	const [surveyId, setSurveyId] = useState(undefined);
	const [prevQuestion,setPrevQuestion] = useState(undefined);
	const [nextQuestion,setNextQuestion] = useState(undefined);
	const [skipRules, setSkipRules] = useState(undefined)
	const [hideQuestions,setHideQuestions] = useState(undefined)
	const [pageId, setPageId] = useState(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [question, setQuestion] = useState(undefined);
	const [section, setSection] = useState(undefined);
	const [isSurveyReview, setIsSurveyReview] = useState(false);
	const [error, setError] = useState(undefined);
	const [questionDescriptors, setQuestionDescriptors] = useState(undefined);
	const [responses, setResponses] = useState(undefined);
	const [sectionDescriptors, setSectionDescriptors] = useState(undefined);
	const [isReview, setIsReview] = useState(false);
	const [isSection, setIsSection] = useState(false);
	const [sectionColor, setSectionColor] = useState(undefined);
	const { instrument } = config
	const [questionCount,setQuestionCount] = useState(undefined);
	const [activeSurveyResponse,setActiveSurveyResponse] = useState(undefined);

	const updateSectionColor = () => {
		if( !sectionColor || sectionColor === 'pink' ) {
			setSectionColor('orange')
		} else {
			setSectionColor('pink');
		}
	}
	const handleLanguage = (
		event,
		newLanguage,
	) => {
		event.preventDefault();
		setLanguage(newLanguage);
	};

	useEffect(() => {
        if (!surveyId) return; // Exit early if surveyID does not exist

        const checkTimeAndCallAPI = async () => {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinutes = now.getMinutes();

            // Check if it's 4 PM or later
            if (currentHour > 16 || (currentHour === 16 && currentMinutes >= 0)) {
                    // Make API call
                    await completeSurvey()
					logout()
            }
        };

        // Set interval to check time every 30 seconds
        const intervalId = setInterval(checkTimeAndCallAPI, 30000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [surveyId]); // Re-run effect if surveyID changes or survey is completed



	const checkSkip = useCallback(async (question, skipRules, surveyID, forward) => {
		if(!skipRules || !skipRules.length) return  question;
		const rules = skipRules.filter(sr => sr.rule.CheckForwardSkip === question.QuestionID || question.QuestionID === sr.rule.CheckBackwardSkip)
		if(!rules || !rules.length) return question

		let isValid = false
		let rule;
		for(let j = 0; j < rules.length; j++) {
			const r = rules[j];
			for(let i=0; i < r.values.length; i++) {
				const value = r.values[i]
				const activeResponse = await apiService.getActiveSurveyResponse(surveyID,value.QuestionID)

				if(activeResponse[0]?.ResponseID === value.ResponseID) {
					isValid = true
					rule = r;
					break;
				}
			}
		}


		if(!isValid || ! rule) return question

		const questionID = forward ? rule.rule.ForwardSkipQuestion : rule.rule.BackwardSkipQuestion
		return apiService.getQuestionById(questionID)

	}, [])

	useEffect(() => {
		const initSurvey = async () => {
			try {
				setIsLoading(true)
				setError(false)
				const lastViewedQuestion = await apiService.getQuestionById(survey.LastViewedQuestionID);
				await prepareQuestion(lastViewedQuestion.PageID)
				const { count } = await apiService.getQuestionCountByInstrument(config.instrument)
				const questionSkipRules = await apiService.getQuestionSkipRuleData(applicationSession)
				const questionSkipRuleValues = await apiService.getQuestionSkipRulValueData(applicationSession)
				const questionHides = await apiService.getQuestionHideData(applicationSession,userSchoolClass.DataCollectorTeamID)
				if(questionHides && questionHides.length) {
					const filteredQuestionHides = questionHides.filter(q => q.School_id === userSchoolClass.School_id)
					if(filteredQuestionHides && filteredQuestionHides.length) {
						const hideQuestion = []
						for(let i = 0; i < filteredQuestionHides.length; i++) {
							const que = await apiService.getQuestionById(filteredQuestionHides[i].QuestionID)
							hideQuestion.push({PageID: que.PageID, QuestionID: que.QuestionID})
						}
						setHideQuestions(hideQuestion)
					}
				}

				const rulesArr = []
				for(let i = 0; i < questionSkipRules.length; i++) {
					const rule = questionSkipRules[i]
					const values = questionSkipRuleValues.filter(val => val.QuestionSkipRule === rule.QuestionSkipRuleID)
					rulesArr.push({ rule, values })
				}
				setSkipRules(rulesArr)
				setPageId(lastViewedQuestion.PageID)
				setSurveyId(survey.SurveyID)
				setQuestionCount(count)
				setIsLoading(false)

			} catch (err) {
				setError(true)
				setIsLoading(false)
			}
		}

		(async () => {
			await initSurvey()
		})()

	},[])


	useEffect(() => {
		if(question && !question.EndOfSurvey) {
			prepareNextQuestion(question.PageID);
		}
		if(question && pageId>1){
			preparePrevQuestion(question.PageID)
		}
	}, [question])
	useEffect(() => {
		if(getSurveyStatus() ==='In-Progress'){
			setSurveyStart(true);
			setSurveyInstructionsViewed(true);
		}
	}, [surveyStatus])
	useEffect(()=>{
		if(error){
			toast.warn(t('SurveyError'),{
				position: "top-center",
				duration: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				draggable: false,
			})
			reset();
		}
	},[error])
	const updateQuestionDisplayed = async (question,surveyId, backupId) => {
		try {
			const updatedSurveyResponse = await apiService.setDisplayed([question.QuestionID], surveyId, backupId)
			console.log(updatedSurveyResponse, 'you knoww  hthhtuiusab ---------------------')
			if(updatedSurveyResponse) {
				setActiveSurveyResponse([updatedSurveyResponse])
			}
		} catch (err) {}
	}

	useEffect(() => {
		if(question && surveyId) {
			updateQuestionDisplayed(question, surveyId, backupID)
		}

	}, [question, surveyId, backupID])


	const getUserClass = () => userSchoolClass.School_name
	const reset = () => {
		logout()
	};
	const prepareNextQuestion = async (pageId) => {
		const recursivelyHideNextPageId = (pid) => {
			if(hideQuestions && !!hideQuestions.length) {
				const shouldHide = hideQuestions.find(hq => hq.PageID === pid)
				if(shouldHide) {
					return recursivelyHideNextPageId(pid + 1)
				} else {
					return pid
				}
			} else {
				return pid
			}

		}
		const nextPageId = recursivelyHideNextPageId(pageId +1)

		const {
			isSection,
			Questions,
			Section,
			Responses,
			SurveyResponses
		} =  await apiService.loadQuestion(nextPageId, survey.SurveyID, backupID)
		const newQuestion= Questions[0]?.question
		if(!newQuestion){
			return
		}
		const nextQuestionObj={};
		nextQuestionObj.question=newQuestion;
		const newSection = Section[0].section
		const newSectionDescriptors = Section[0].sectionDescriptors
		newSectionDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
		if (isSection) {
			nextQuestionObj.IsSection = true;
			nextQuestionObj.Section = newSection;
			nextQuestionObj.sectionDescriptors = newSectionDescriptors;
			nextQuestionObj.question = newQuestion
			nextQuestionObj.updateSectionColor = true;
		} else {
			newQuestion.section = newSection;
			newQuestion.sectionDescriptors = newSectionDescriptors;
			const newQuestionDescriptors = Questions[0].questionDescriptors
			newQuestionDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
			const newResponses = Responses
			newResponses.sort((a, b) => a.response.DisplaySortOrder - b.response.DisplaySortOrder);

			for (let i = 0; i < newResponses.length; i++) {
				const newResponseDescriptors = newResponses[i].responseDescriptors
				console.log(newQuestionDescriptors, 'arrr')
				newResponseDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
				newResponses[i].response.descriptors = newResponseDescriptors;
				newResponses[i] = newResponses[i].response

			}
			nextQuestionObj.question = newQuestion
			nextQuestionObj.questionDescriptors=newQuestionDescriptors;
			nextQuestionObj.responses = newResponses;
			nextQuestionObj.activeResponse = SurveyResponses
		}

		setNextQuestion(nextQuestionObj);

	}
	const preparePrevQuestion = async (pageId) => {
		const recursivelyHidePreviousPageId = (pid) => {
			if(hideQuestions && !!hideQuestions.length) {
				const shouldHide = hideQuestions.find(hq => hq.PageID === pid)
				if(shouldHide) {
					return recursivelyHidePreviousPageId(pid - 1)
				} else {
					return pid
				}
			} else {
				return pid
			}
		}
		const previousPageId = recursivelyHidePreviousPageId(pageId - 1)

		const {
			isSection,
			Questions,
			Section,
			Responses,
			SurveyResponses
		} =  await apiService.loadQuestion(previousPageId, survey.SurveyID, backupID)
		const newQuestion= Questions[0]?.question
		if(!newQuestion){
			return
		}
		const prevQuestionObj={};
		prevQuestionObj.question=newQuestion;
		const newSection = Section[0].section
		const newSectionDescriptors = Section[0].sectionDescriptors
		newSectionDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
		if (isSection) {
			prevQuestionObj.IsSection = true;
			prevQuestionObj.Section = newSection;
			prevQuestionObj.sectionDescriptors = newSectionDescriptors;
			prevQuestionObj.question = newQuestion
			prevQuestionObj.updateSectionColor = true;
		} else {
			newQuestion.section = newSection;
			newQuestion.sectionDescriptors = newSectionDescriptors;
			const newQuestionDescriptors = Questions[0].questionDescriptors
			newQuestionDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
			const newResponses = Responses
			newResponses.sort((a, b) => a.response.DisplaySortOrder - b.response.DisplaySortOrder);

			for (let i = 0; i < newResponses.length; i++) {
				const newResponseDescriptors = newResponses[i].responseDescriptors
				newResponseDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
				newResponses[i].response.descriptors = newResponseDescriptors;
				newResponses[i] = newResponses[i].response

			}
			prevQuestionObj.question = newQuestion
			prevQuestionObj.questionDescriptors=newQuestionDescriptors;
			prevQuestionObj.responses = newResponses;
			prevQuestionObj.activeResponse = SurveyResponses
		}

		setPrevQuestion(prevQuestionObj);

	}
	
	
	const setQuestionbyQuestionObj = async (newQuestionObj) => {
		setIsSection(false);
		setSection(undefined);
		setSectionDescriptors(undefined);
		setResponses(undefined);
		setQuestionDescriptors(undefined);
		setQuestion(undefined);
		if (!newQuestionObj.question.ResponseRequired) {
			setIsSection(true);
			setSection(newQuestionObj.Section);
			setSectionDescriptors(newQuestionObj.sectionDescriptors);
			console.log("setQuestion", newQuestionObj,section,isSection,sectionDescriptors)
			setQuestion(newQuestionObj.question);
			updateSectionColor();
		} else {
			setQuestionDescriptors(newQuestionObj.questionDescriptors);
			setResponses(newQuestionObj.responses);
			setActiveSurveyResponse(newQuestionObj.activeResponse);
			setQuestion(newQuestionObj.question);
		}
	}
	const prepareQuestion = async (pageId) => {
		setIsSection(false);
		setSection(undefined);
		setSectionDescriptors(undefined);
		setResponses(undefined);
		setQuestionDescriptors(undefined);
		setQuestion(undefined);
		const {
			isSection,
			Questions,
			Section,
			Responses,
			SurveyResponses
		} = await apiService.loadQuestion(pageId, survey.SurveyID, backupID)

		const newQuestion = Questions[0].question
		const newSection = Section[0].section
		const newSectionDescriptors = Section[0].sectionDescriptors
		newSectionDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
		if (isSection) {
			setIsSection(true);
			setSection(newSection);
			setSectionDescriptors(newSectionDescriptors);
			setQuestion(newQuestion);
			updateSectionColor();
		} else {
			newQuestion.section = newSection;
			newQuestion.sectionDescriptors = newSectionDescriptors;
			const newQuestionDescriptors = Questions[0].questionDescriptors
			newQuestionDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
			const newResponses = Responses
			newResponses.sort((a, b) => a.response.DisplaySortOrder - b.response.DisplaySortOrder);

			for (let i = 0; i < newResponses.length; i++) {
				const newResponseDescriptors = newResponses[i].responseDescriptors
				newResponseDescriptors.sort((a, b) => a.DisplaySortOrder - b.DisplaySortOrder);
				newResponses[i].response.descriptors = newResponseDescriptors;
				newResponses[i] = newResponses[i].response
			}

			setQuestion(newQuestion);
			setQuestionDescriptors(newQuestionDescriptors);
			setResponses(newResponses);
			setActiveSurveyResponse(SurveyResponses);
		}

		setPageId(pageId)

	};

	const navigateTo = async (el) =>{ 
		try {
			setError(undefined);
			setIsLoading(true);
			setIsSurveyReview(false);
			setIsReview(true);
			if (question) {
				let nextPageId = el.PageID;
				setPageId(nextPageId);
				await prepareQuestion(nextPageId);
			} else {
				reset();
			}
			setIsLoading(false);
		} catch (err) {
			setError("There was an error navigating to the next question. Please try again");
			setIsLoading(false);
		}
	};

	const navigateNext = async () => {
		console.log('Invooked Naviaget next')
		try {
			setIsLoading(true);
			setError(false);
			if (question && question.EndOfSurvey !== 1) {
				const recursivelyHideNextPageId = (pid) => {
					if(!!hideQuestions && !!hideQuestions.length) {
						const shouldHide = hideQuestions.find(hq => hq.PageID === pid)
						if(shouldHide) {
							return recursivelyHideNextPageId(pid + 1)
						} else {
							return pid
						}
					} else {
						return pid
					}
				}

				const nextPageId = recursivelyHideNextPageId(pageId +1)
				if(!isEmpty(nextQuestion) && ((nextQuestion.question && nextPageId === nextQuestion.question.PageID) || (nextQuestion.section && nextQuestion.section.PageID === nextPageId ))){
					var cur_questionObj = nextQuestion;
					const skipQuestion = await checkSkip(cur_questionObj.question, skipRules, surveyId, true)
					if(skipQuestion.QuestionID !== cur_questionObj.question.QuestionID) {
						if(!isReview) {
							await apiService.updateLastQuestionViewed(surveyId, cur_questionObj.question.QuestionID);
						}
						await prepareQuestion(skipQuestion.PageID);
					} else  {
						setPageId(nextPageId);

						if(!isReview) {
							await apiService.updateLastQuestionViewed(surveyId, cur_questionObj.question.QuestionID);
						}
						await setQuestionbyQuestionObj(cur_questionObj);
					}
				} else {
					const newQuestionArr = await apiService.getQuestionsByPageID(nextPageId);
					let newQuestion = newQuestionArr[0];
					newQuestion = await checkSkip(newQuestion, skipRules, surveyId, true)

					if(!isReview) {
						await apiService.updateLastQuestionViewed(surveyId, newQuestion.QuestionID);
					}
					await prepareQuestion(newQuestion.PageID);
				}
			} else {
				reset();
			}

			setIsLoading(false);
		} catch (err) {
			setError("There was an error navigating to the next question. Please try again");
			setIsLoading(false);
		}
	};

	const prevPageNavProcessor = async (currentPageID) => {
		try {
			setError(undefined);
			setIsLoading(true);
			const recursivelyHidePreviousPageId = (pid) => {
				if(!!hideQuestions && !!hideQuestions.length) {
					const shouldHide = hideQuestions.find(hq => hq.PageID === pid)
					if(shouldHide) {
						return recursivelyHidePreviousPageId(pid - 1)
					} else {
						return pid
					}

				} else {
					return pid
				}
			}

			const prevPageId = recursivelyHidePreviousPageId(currentPageID - 1)


			if (prevPageId > 0) {
				if(!isEmpty(prevQuestion) &&  ((prevQuestion.question && prevPageId === prevQuestion.question.PageID) || (prevQuestion.section && prevQuestion.section.PageID === prevPageId ))){
					let cur_questionObj = prevQuestion;
					const skipQuestion = await checkSkip(cur_questionObj.question, skipRules, surveyId, false)
					if(skipQuestion.QuestionID !== cur_questionObj.question.QuestionID) {
						if(!isReview) {
							await apiService.updateLastQuestionViewed(surveyId, skipQuestion.QuestionID);
						}
						await prepareQuestion(skipQuestion.PageID);
					} else  {
						setPageId(prevPageId);

						if(!isReview) {
							await apiService.updateLastQuestionViewed(surveyId, cur_questionObj.question.QuestionID);
						}
						await setQuestionbyQuestionObj(cur_questionObj);
					}
				} else {
				const newQuestionArr = await apiService.getQuestionsByPageID(prevPageId);
				const newQuestion = newQuestionArr[0];
				if(!isReview) {
					await apiService.updateLastQuestionViewed(surveyId, newQuestion.QuestionID);
				}
				await prepareQuestion(newQuestion.PageID);
				}
			} else {
				setSurveyStart(false);
				setSurveyInstructionsViewed(false);
			}
			setIsLoading(false);
		} catch (err) {
			setError("There was an error navigating to the previous question. Please try again");
			setIsLoading(false);
		}
	};

	const navigatePrevious = async () => {
		let currentPageID = pageId;
		await prevPageNavProcessor(currentPageID);
	};

	const getSurveyStatus = () => {
		return surveyStatus;
	};

	const completeSurvey = async () => {
		try {
			await apiService.completeSurvey(surveyId);
		} catch (err) {
			setError(true)
		}
	}

	const handleError = async () => {
		try {
			setIsLoading(true);
			await prepareQuestion(pageId);
			setError(undefined);
			setIsLoading(false);
		} catch (err) {}
	};

	const getInstructionsViewedStatus = () => {
		return surveyInstructionsViewed;
	};


	if (isLoading) {
		return <LoadingIndicator text={"Loading..."} />;
	}


	if ((!surveyInstructionsViewed && surveyStatus === 'New')) {
		return (
			<SurveyInstructionsScreen
				getClass={getUserClass}
				setViewed={(viewed) => setSurveyInstructionsViewed(viewed)}
				getSurveyStatus={getSurveyStatus}
				setStart={(start) => setSurveyStart(start)}
				getInstructions={getInstructionsViewedStatus}
				language={language}
				setLanguage={(l)=>setLanguage(l)}
				handleLanguage={(e,lng) =>handleLanguage(e,lng)}
			/>
		);
	}

	if (surveyInstructionsViewed && !surveyStarted) {
		return (
			<SurveyInstructionsScreen
				getClass={getUserClass}
				setViewed={(viewed) => setSurveyInstructionsViewed(viewed)}
				getSurveyStatus={getSurveyStatus}
				setStart={(start) => setSurveyStart(start)}
				getInstructions={getInstructionsViewedStatus}
				language={language}
				setLanguage={(l)=>setLanguage(l)}
				handleLanguage={(e,lng) =>handleLanguage(e,lng)}
			/>
		);
	}

	if (surveyInstructionsViewed && surveyStarted && isSurveyReview) {
		return (
		 <SurveyReviewScreen
			surveyId={surveyId}
			navigateTo={navigateTo}
			reset={() => reset()}
			completeSurvey={() => completeSurvey()}
			setIsSurveyReview={(review) => setIsSurveyReview(review)}
			skipRules={skipRules}
			checkSkip={checkSkip}
			language={language}
			setLanguage={(l)=>setLanguage(l)}
			handleLanguage={(e,lng) =>handleLanguage(e,lng)}
			hideQuestions={hideQuestions}
			isWeb
		/>
		);
	}

	if (surveyInstructionsViewed && surveyStarted && question && questionDescriptors && responses) {
		return (
			<QuestionsScreen
				question={question}
				questionDescriptors={questionDescriptors}
				survey={surveyId}
				section={section}
				sectionDescriptors={sectionDescriptors}
				previous={navigatePrevious}
				next={navigateNext}
				responses={responses}
				sectionColor={sectionColor}
				page={pageId}
				questionCount={questionCount}
				isSection={isSection}
				isReview={isReview}
				setIsSurveyReview={(review) => setIsSurveyReview(review)}
				setIsReview={(review) =>setIsReview(review)}
				activeSurveyResponse={activeSurveyResponse}
				isLoading={isLoading}
				isWeb
				backupID={backupID}
				language={language}
				skipRules={skipRules}
				setLanguage={(l)=>setLanguage(l)}
				handleLanguage={(e,lng) =>handleLanguage(e,lng)}
			/>
		);
	}

	if (surveyInstructionsViewed && surveyStarted && isSection && section && sectionDescriptors) {
		return (
			<SectionScreen
				setIsSurveyReview={(review) => setIsSurveyReview(review)}
				sectionColor={sectionColor}
				next={navigateNext}
				previous={navigatePrevious}
				section={section}
				sectionDescriptors={sectionDescriptors}
				pageID={pageId}
				isSection={isSection}
				survey={surveyId}
				isWeb
				language={language}
				isLoading={isLoading}
				setLanguage={(l)=>setLanguage(l)}
				handleLanguage={(e,lng) =>handleLanguage(e,lng)}

			/>
		);
	}
	console.log(question, section, nextQuestion, prevQuestion)
	return <LoadingIndicator text={"Loading..."} />;
}
