import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useLocation
} from "react-router-dom";
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile
} from "react-device-detect";

import './index.css'
import config from '../config'
import useAppState from "../hooks/useAppState";
import QuestionsScreen from "../screens/QuestionsScreen";
import LoginScreen from "../screens/LoginScreen";
import SettingsScreen from "../screens/SettingsScreen";
import {
	CODES_ROUTE, CREATE_PIN_ROUTE,
	LOGIN_ROUTE,
	QUESTIONS_ROUTE,
	LOCK_ROUTE,
	SETTINGS_ROUTE,
	LOCK_BUTTON_ROUTE,
	STUDENT_LOGIN_ROUTE,
	SURVEY_ROUTE
} from "../config/routes";
import ApplicationBar from "../components/ApplicationBar";
import PWAInstall from "../components/PWAInstall";
import Typography from "@material-ui/core/Typography";
import LockScreen from "../screens/LockScreen";
import LockButtonScreen from "../screens/LockButtonScreen"
import Message from "../components/Message";
import CodesScreen from "../screens/CodesScreen";
import StudentLoginScreen from "../screens/StudentLoginScreen";
import SurveyScreen from "../screens/SurveyScreen";
import CreatePINScreen from "../screens/CreatePINScreen";
import {makeStyles} from "@material-ui/core";
import { QueryBuilder } from "@material-ui/icons";
import useWebAppState from "../hooks/useWebAppState";

const useStyles = makeStyles({
		scroll: {
			overflowY: "auto",
			overflowX: 'hidden',
			margin: 0,
			padding: 0,
			listStyle: "none",
			height: "100%",
			'&::-webkit-scrollbar': {
				width: '0.7em',
				backgroundColor: '#BFBFBF'

			},
			'&::-webkit-scrollbar-track': {
				boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				color: '#BFBFBF'
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#215181'
			}
		}
	}
)

export const WebRouter = () => {
	const classes = useStyles();
	const {
		survey,
		userSchoolClass,
		studentLoginID,
		surveyStatus
	} = useWebAppState()

	return (
		<div className={`flex-column full-height full-width align-center`}>
			<ApplicationBar />
			<div className={`application-router-container flex-column full-height full-width ${classes.scroll}`}>
				{(!!survey && !!studentLoginID && !!userSchoolClass && (surveyStatus !== undefined)) ?  <SurveyScreen /> : <StudentLoginScreen />}
			</div>
		</div>
	)
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
  }
  
function AuthenticatedApp({isOnline, user, session}) {
	let query = useQuery();
	if(isOnline && (!user || user === "0" || user === 0 || !session)) {
		return (<LoginScreen/>)
	}
	return (
		<>
			<Switch>
				<Route path={SETTINGS_ROUTE}>
					<SettingsScreen/>
				</Route>
				<Route path={CODES_ROUTE}>
					<CodesScreen />
				</Route>
				<Route path={SURVEY_ROUTE}>
					<SurveyScreen />
				</Route>
				<Route path={CREATE_PIN_ROUTE}>
					<CreatePINScreen settings={query.get("page")}/>
				</Route>
				<Route path={'*'}>
					<CreatePINScreen />
				</Route>
			</Switch>
		</>
	)
}

function UnAuthenticatedApp({isOnline, user, session, jwt}) {
	if(isOnline && (!user || user === "0" || user === 0 || !session || !jwt)) {
		return (<LoginScreen/>)
	}

	return (
		<>
			<Switch>
				<Route path={SURVEY_ROUTE}>
					<SurveyScreen />
				</Route>
				<Route path={LOCK_ROUTE}>
					<LockScreen />
				</Route>
				<Route path={'*'}>
					<LockButtonScreen/>
				</Route>
			</Switch>
		</>
	)
}

export default function ApplicationRouter() {
	const {
		isInstalled,
		isOnline,
		user,
		isLocked,
		session,
		jwt
	} = useAppState();

	const locked = isLocked();
	const classes = useStyles();

	if(!isInstalled && isMobile) {
		return (
			<div className={`flex-column full-height full-width align-center ${classes.scroll}`}>
				<PWAInstall title={config.appTitle} description={config.appDescription}>
					<div className="application-router-container flex-column full-height full-width align-center justify-center">
						<Typography style={{textAlign: 'center'}} variant={'h4'}>Application is Not Supported on this device/browser</Typography>
					</div>
				</PWAInstall>
			</div>
		)
	}

	if (!isOnline && (!user || user === "0" || user === 0)) {
		return (
			<div className={`flex-column full-height full-width align-center ${classes.scroll}`}>
				<Message title={'No user Registered for Offline mode'} text={'Please connect to the internet and reload the page'} />
			</div>
		)
	}

	return (
		<div className={`flex-column full-height full-width align-center`}>
			<Router>
				<ApplicationBar />
				<div className={`application-router-container flex-column full-width ${classes.scroll}`}>
					{!!locked && <UnAuthenticatedApp session={session} isOnline={isOnline} user={user} jwt={jwt}/>}
					{!locked && <AuthenticatedApp session={session} isOnline={isOnline} user={user} />}
				</div>
			</Router>
		</div>
	)
}

